/** @format */

import http from '../js/http-client';

class InfoApi {
	/**
	 * 获取首页初始化信息
	 * @param {any} data:PostData
	 * @returns {Promise}
	 */
	homeInit() {
		return http.request({
			url: '/api/home/init-get',
			method: 'post',
		});
	}

	/**
	 * 获取协议列表内容
	 */
	agreementList() {
		return http.request({
			url: '/api/agreement/agreement-list',
			method: 'get',
		});
	}

	/**
	 * 获取协议详情内容
	 */
	agreementDetail(id: string) {
		return http.request({
			url: `/api/agreement/agreement-by-id/${id}`,
			method: 'get',
		});
	}
}

const infoApi = new InfoApi();

export default infoApi;
